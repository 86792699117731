import React from "react";
import GooglePlayButton from "./GooglePlayButton";
import AppStoreButton from "./AppStoreButton";

const JoinFlat = () => {
  return (
    <div className="min-h-screen bg-[#1c1c1c] flex flex-col justify-center items-center px-4">
      <h1 className="font-gnarly-semibold text-white text-5xl md:text-7xl mb-8 text-center">
        Join Flat
      </h1>
      <div className="flex flex-wrap justify-center gap-4">
        <GooglePlayButton url={"#"} />
        <AppStoreButton url={"#"} />
      </div>
    </div>
  );
};

export default JoinFlat;
