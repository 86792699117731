import React from "react";
import { useTranslation } from "react-i18next";
import GooglePlayButton from "./GooglePlayButton";
import AppStoreButton from "./AppStoreButton";

// Direct paths to your images
const imagePathDark = "/240822-Flateroo-Logo-black-rgb.svg";
const imagePathLight = "/240822-Flateroo-Logo-white-rgb.svg";

function Top() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center text-center h-svh px-10">
      {/* Light mode image */}
      <img
        src={imagePathLight}
        alt="Flateroo Logo"
        className="max-w-[90vh] h-auto hidden dark:block"
      />
      {/* Dark mode image */}
      <img
        src={imagePathDark}
        alt="Flateroo Logo"
        className="max-w-[90vh] h-auto block dark:hidden"
      />

      <h2 className="text-2xl md:text-3xl font-gnarly-semibold text-black dark:text-[#cfcfcf] mt-20">
        {t("subheading")}
      </h2>
      <div className="flex items-center space-x-4 md:space-x-10 mt-2 md:mt-5">
        <GooglePlayButton url={"#"} />
        <AppStoreButton url={"#"} />
      </div>
    </div>
  );
}

export default Top;
