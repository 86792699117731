import React from "react";
import { useTranslation } from "react-i18next";
import { FaLinkedin, FaLink } from "react-icons/fa";
import { TbFileCv } from "react-icons/tb";

const Founders = () => {
  const iconSize = 20;
  const { t } = useTranslation();

  return (
    <div className="m-10 flex flex-col items-center  text-black dark:text-[#cfcfcf] ">
      <h1 className="text-3xl font-gnarly-semibold mb-6">
        {t("founders.meet_founders")}
      </h1>

      <div className="flex flex-col sm:space-y-0 space-y-10 lg:flex-row items-center justify-evenly ">
        <div className="flex flex-col items-center p-4 md:p-8">
          <div className="w-48 h-48 rounded-full overflow-hidden mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/founder1.jpg`}
              alt="Niki"
              className="w-full h-full object-cover"
            />
          </div>
          <h2 className="text-xl font-gnarly-semibold mb-2">
            Niki <span className="text-lg font-gnarly-regular">(CEO)</span>
          </h2>
          <p className="text-center font-gnarly-regular">
            {t("founders.founder1")}
          </p>
          <div className="flex flex-row space-x-4 mt-4">
            <a
              href="https://www.linkedin.com/in/nikolaus-brissa"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black dark:text-[#cfcfcf] hover:text-black hover:scale-110 dark:hover:text-white"
            >
              <FaLinkedin size={iconSize} />
            </a>
            <a
              href="https://www.nikolausbrissa.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black dark:text-[#cfcfcf] hover:text-black hover:scale-110 dark:hover:text-white"
            >
              <FaLink size={iconSize} />
            </a>
            <a
              href="https://drive.google.com/file/d/1yjQMUud4WIpNCerI-1qhrtkoiMKnFLV4/view?usp=drive_link"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black dark:text-[#cfcfcf] hover:text-black hover:scale-110 dark:hover:text-white"
            >
              <TbFileCv size={iconSize} />
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center p-4 md:p-8">
          <div className="w-48 h-48 rounded-full overflow-hidden mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/founder2.jpg`}
              alt="Lorin Urbantat"
              className="w-full h-full object-cover"
            />
          </div>
          <h2 className="text-xl font-gnarly-semibold mb-2">
            Lorin Urbantat{" "}
            <span className="text-lg font-gnarly-regular">(CTO)</span>
          </h2>
          <p className="text-center font-gnarly-regular">
            {t("founders.founder2")}
          </p>
          <div className="flex flex-row space-x-4 mt-4">
            <a
              href="https://www.linkedin.com/in/bruol/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black dark:text-[#cfcfcf] hover:text-black hover:scale-110 dark:hover:text-white"
            >
              <FaLinkedin size={iconSize} />
            </a>
            <a
              href="https://bruol.me/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black dark:text-[#cfcfcf] hover:text-black hover:scale-110 dark:hover:text-white"
            >
              <FaLink size={iconSize} />
            </a>
            <a
              href="https://bruol.me/other/cv.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black dark:text-[#cfcfcf] hover:text-black hover:scale-110 dark:hover:text-white"
            >
              <TbFileCv size={iconSize} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders;
