import React from "react";
import LanguageSelector from "./Languageselector";
import ThemeToggle from "./ThemeToggle";
import { Link } from "react-router-dom"; // Import Link for navigation
import { useTranslation } from "react-i18next";

function Header({ changeLanguage, scrollToApp, scrollToFounders }) {
  const { t } = useTranslation();

  return (
    <div className="w-full px-2 md:px-5 h-16 border-b text-black dark:text-[#cfcfcf] border-black dark:border-[#cfcfcf] flex items-center justify-between">
      <ThemeToggle />

      <div className="flex-1 flex justify-center space-x-10">
        <a href="/" className="font-gnarly-semibold text-lg hover:underline">
          Flateroo
        </a>
        <Link
          to="/investors"
          className="font-gnarly-semibold text-lg hover:underline"
        >
          {t("investors")}
        </Link>
        <a
          className="font-gnarly-semibold text-lg hover:underline cursor-pointer hidden md:inline-block"
          onClick={scrollToApp}
        >
          App
        </a>
        <a
          className="font-gnarly-semibold text-lg hover:underline cursor-pointer hidden md:inline-block"
          onClick={scrollToFounders}
        >
          {t("team")}
        </a>
      </div>
      <LanguageSelector changeLanguage={changeLanguage} />
    </div>
  );
}

export default Header;
