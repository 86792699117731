import React, { useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import * as THREE from "three";

const fragmentShader = `
  varying vec2 vUv;
  uniform sampler2D videoTexture;
  uniform float saturationFactor;
  uniform float contrastFactor;
  uniform float gammaFactor;
  uniform float brightnessFactor; // Added to control brightness

  vec3 rgb2hsl(vec3 color) {
    float maxVal = max(max(color.r, color.g), color.b);
    float minVal = min(min(color.r, color.g), color.b);
    float chroma = maxVal - minVal;
    float l = (maxVal + minVal) * 0.5;
    float h = 0.0;
    float s = 0.0;

    if (chroma > 0.0) {
      if (maxVal == color.r) {
        h = mod((color.g - color.b) / chroma, 6.0);
      } else if (maxVal == color.g) {
        h = (color.b - color.r) / chroma + 2.0;
      } else {
        h = (color.r - color.g) / chroma + 4.0;
      }
      s = chroma / (1.0 - abs(2.0 * l - 1.0));
    }

    return vec3(h / 6.0, s, l);
  }

  vec3 hsl2rgb(vec3 hsl) {
    float h = hsl.x;
    float s = hsl.y;
    float l = hsl.z;

    float c = (1.0 - abs(2.0 * l - 1.0)) * s;
    float x = c * (1.0 - abs(mod(h * 6.0, 2.0) - 1.0));
    float m = l - 0.5 * c;

    vec3 color = vec3(0.0);

    if (0.0 <= h && h < 1.0 / 6.0) {
      color = vec3(c, x, 0.0);
    } else if (1.0 / 6.0 <= h && h < 2.0 / 6.0) {
      color = vec3(x, c, 0.0);
    } else if (2.0 / 6.0 <= h && h < 3.0 / 6.0) {
      color = vec3(0.0, c, x);
    } else if (3.0 / 6.0 <= h && h < 4.0 / 6.0) {
      color = vec3(0.0, x, c);
    } else if (4.0 / 6.0 <= h && h < 5.0 / 6.0) {
      color = vec3(x, 0.0, c);
    } else {
      color = vec3(c, 0.0, x);
    }

    return color + m;
  }

  vec3 adjustContrast(vec3 color, float contrast) {
    return (color - 0.5) * contrast + 0.5;
  }

  vec3 applyGamma(vec3 color, float gamma) {
    return pow(color, vec3(gamma));
  }

  void main() {
    vec4 videoColor = texture2D(videoTexture, vUv);

    // Linearize the color before applying adjustments
    vec3 linearColor = applyGamma(videoColor.rgb, 1.0 / gammaFactor);

    // Convert to HSL and adjust saturation
    vec3 hslColor = rgb2hsl(linearColor);
    hslColor.y *= saturationFactor;
    vec3 rgbColor = hsl2rgb(hslColor);

    // Apply contrast adjustment
    rgbColor = adjustContrast(rgbColor, contrastFactor);

    // Apply brightness adjustment
    rgbColor += brightnessFactor; // Lift brightness slightly

    // Re-apply gamma correction
    rgbColor = applyGamma(rgbColor, gammaFactor);

    gl_FragColor = vec4(rgbColor, videoColor.a);
  }
`;

function IPhoneModel({ mouse, isMouseMoving }) {
  const { scene } = useGLTF("/models/iphone18.glb");
  const modelRef = useRef();
  const videoRef = useRef();

  useEffect(() => {
    const video = document.createElement("video");
    video.src = "/videos/phone1.mp4";
    video.crossOrigin = "anonymous";
    video.loop = true;
    video.muted = true;
    video.play();

    // Create a VideoTexture
    const texture = new THREE.VideoTexture(video);
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBFormat;
    texture.toneMapping = THREE.ACESFilmicToneMapping;
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.needsUpdate = true;

    const screenMesh = scene.getObjectByName("screen_glass");
    if (screenMesh) {
      screenMesh.material = new THREE.ShaderMaterial({
        uniforms: {
          videoTexture: { value: texture },
          saturationFactor: { value: 0.75 }, // Slightly more saturated
          contrastFactor: { value: 1.05 }, // Slightly reduced contrast
          gammaFactor: { value: 0.95 }, // Reduced gamma for better dark area display
          brightnessFactor: { value: 0.05 }, // Lift dark areas slightly
        },
        vertexShader: `
          varying vec2 vUv;
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader,
      });
    }
    videoRef.current = video;

    // Enable shadows for the model
    scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });

    return () => {
      video.pause();
      video.src = "";
      video.load();
    };
  }, [scene]);

  useFrame(() => {
    if (modelRef.current) {
      const targetY = mouse.x * Math.PI * 0.2;
      const targetX = -mouse.y * Math.PI * 0.2;

      if (isMouseMoving) {
        modelRef.current.rotation.y = THREE.MathUtils.lerp(
          modelRef.current.rotation.y,
          targetY,
          0.1 // Adjust the interpolation speed as needed
        );
        modelRef.current.rotation.x = THREE.MathUtils.lerp(
          modelRef.current.rotation.x,
          targetX,
          0.1 // Adjust the interpolation speed as needed
        );
      } else {
        modelRef.current.rotation.y = THREE.MathUtils.lerp(
          modelRef.current.rotation.y,
          0,
          0.05
        );
        modelRef.current.rotation.x = THREE.MathUtils.lerp(
          modelRef.current.rotation.x,
          0,
          0.05
        );
      }
    }
  });

  return <primitive ref={modelRef} object={scene} position={[0, 0, 0]} />;
}

function MovingLight() {
  const lightRef = useRef();
  let angle = 0;

  useFrame(() => {
    angle += 0.02;

    const x = 10 * Math.cos(angle);
    const z = 10 * Math.sin(angle);

    if (lightRef.current) {
      lightRef.current.position.set(x, 10, z);
    }
  });

  return <directionalLight ref={lightRef} intensity={10} color="#fffff5" />;
}

function Floor() {
  return (
    <mesh receiveShadow rotation-x={-Math.PI / 2} position={[0, -0.7, 0]}>
      <planeGeometry args={[100, 100]} />
      <shadowMaterial opacity={0.4} />
    </mesh>
  );
}

export default function App() {
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  let mouseMoveTimeout = useRef(null);

  const handleMouseMove = (event) => {
    setMouse({
      x: (event.clientX / window.innerWidth) * 2 - 1, // Normalize X
      y: -(event.clientY / window.innerHeight) * 2 + 1, // Normalize Y
    });

    setIsMouseMoving(true);

    if (mouseMoveTimeout.current) {
      clearTimeout(mouseMoveTimeout.current);
    }

    mouseMoveTimeout.current = setTimeout(() => {
      setIsMouseMoving(false);
    }, 200); // Adjust the delay as needed
  };

  return (
    <div onMouseMove={handleMouseMove} className="flex w-full h-[80vh]">
      <Canvas camera={{ position: [0, 0, 1.7], fov: 50 }} shadows>
        <directionalLight
          position={[-7, 20, 20]}
          intensity={10}
          castShadow
          shadow-mapSize-width={8192} // Increase shadow map size for better quality
          shadow-mapSize-height={8192}
        />

        <IPhoneModel mouse={mouse} isMouseMoving={isMouseMoving} />
        <MovingLight />
        <Floor />
        <OrbitControls
          enablePan={false} // Disable panning
          enableRotate={false} // Disable OrbitControls rotation since we're controlling it with the mouse
          enableZoom={false} // Enable zooming
          maxPolarAngle={Math.PI}
        />
      </Canvas>
    </div>
  );
}
