import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="border-t border-black dark:border-[#cfcfcf] py-6 text-black dark:text-[#cfcfcf]">
      <div className="container mx-auto flex flex-col justify-between items-center">
        <div className="mb-4 md:mb-0">
          <a href="/" className="text-xl font-gnarly-semibold">
            Flateroo
          </a>
        </div>
        <div className="mb-4 md:mb-0 font-gnarly-regular">
          {t("contact")}: brissanikolaus@gmail.com
        </div>
        <div>
          <p className="text-sm font-gnarly-regular">
            &copy; 2024 Flateroo. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
