import React from "react";

const AppStoreButton = ({ url, className }) => {
  return (
    <>
      {/*     <a
      href={url}
      className={`flex items-center bg-black text-white px-3 py-1 rounded-lg hover:scale-110 transition-transform duration-300  ${className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/app-store-icon.svg`}
        alt="App Store Icon"
        className="w-6 h-6 mr-2"
      />
      <div className="flex flex-col">
        <span className="text-xs">Download on the</span>
        <span className="text-base font-bold">App Store</span>
      </div>
    </a> */}

      <div
        className={`flex items-center bg-black text-white px-3 py-1 rounded-lg cursor-not-allowed hover:scale-110 transition-transform duration-300${className}`}
      >
        <img
          src={`${process.env.PUBLIC_URL}/app-store-icon.svg`}
          alt="App Store Icon"
          className="w-6 h-6 mr-2 "
        />
        <div className="flex flex-col">
          <span className="text-xs">COMING SOON</span>
          <span className="text-base font-bold">App Store</span>
        </div>
      </div>
    </>
  );
};

export default AppStoreButton;
